import { sanitizeUrl } from '@braintree/sanitize-url';
import { COOKIE_KEYS, LOCAL_STORAGE_KEYS, THEMES } from '@constants/constants';
import { Campaign, DynamicVariables } from '@interfaces';
import axios from 'axios';
import * as clipboard from 'clipboard-polyfill';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import xss from 'xss';

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  if (typeof navigator === 'undefined') return false

  return toMatch.some(
    (toMatchItem) =>
      navigator.userAgent.match(toMatchItem) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

export const isAndroid = () => navigator.userAgent.match(/Android/i);

export const isApple = () => {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

export const isServerSide = () => typeof window === 'undefined';

export const isClientSide = () => typeof window !== 'undefined';

export async function getIp() {
  const response = await axios.get('https://ipv4.jsonip.com');
  return response.data.ip;
}

export const reemplaceVariables = (
  text: string,
  variablesValues: ParsedUrlQuery,
  dynamicVariablesEnable = false
) => {
  const variables: string[] = text?.match(/\{{(.*?)\}}/g) ?? [];
  const stringChanged = variables.reduce(
    (acumulatedText: string, value: string) => {
      let acumulatedTextChanged = acumulatedText;
      const textWithoutBrakets = value.substr(2, value.length - 4);
      const [keyText, fallbackValue] = textWithoutBrakets.split('|');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      let newValue: string = (variablesValues[keyText.trim()] as string) ?? '';
      newValue = xss(newValue);
      const isSafeValue = sanitizeUrl(newValue) !== 'about:blank';
      const isValidValue = !/[!#$%^*()_+\-=\\[\]{};:"\\|/?~]/g.test(newValue);
      if (newValue && isSafeValue && isValidValue && dynamicVariablesEnable) {
        // Reemplace | for \| in order to escape it on regexp
        const regex = fallbackValue
          ? new RegExp(value.replace('|', '\\|'), 'g')
          : new RegExp(value, 'g');
        acumulatedTextChanged = acumulatedText.replace(regex, newValue);
      } else if (fallbackValue) {
        acumulatedTextChanged = acumulatedText.replace(
          value,
          fallbackValue.trim()
        );
      }
      return acumulatedTextChanged;
    },
    text
  );
  return stringChanged;
};

export const validateHttpProtocol = (value: string) => {
  const url = value?.trim()
  if (!url) return false

  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^(?:(https?):\/\/)(?:(?:[a-z0-9\-\_\.]+\.)+[a-z]{2,}|(?:[0-9]{1,3}\.){3}[0-9]{1,3})(?::\d+)?(?:\?[^#\s]*)?(?:\/[^\s]*)?(?:#[^#\s]*)?$/i

  return urlRegex.test(url)
}

export const isValidEmail = (email = '') => {
  const trimedEmail = email?.trim()
  if (!trimedEmail) return false

  return /^(mailto:){1}?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimedEmail)
}

export const isValidPhoneNumber = (phoneNumber = '') => {
  const phone = phoneNumber?.trim()
  if (!phone) return false

  return /^(tel:|sms:){1}[+]?[(]?\d{1,4}[)]?[-\s./0-9]*$/.test(phone)
}

export const validateHttpEmailTel = (value: string) => {
  return (
    value === '' ||
    value === null ||
    validateHttpProtocol(value) ||
    isValidPhoneNumber(value) ||
    isValidEmail(value)
  )
}

export const validateAndParseCustomCTAUrl = (currentLink: string, customVariables: any[]) => {
  const regex = /{{(\w+)\s*\|\s*(.*?)}}/g;
  let newLink = currentLink.replace(regex, (_, variableName, fallbackValue) => {
    const variable = customVariables.find(v => v.name === variableName);

    return variable ? variable.value : fallbackValue;
  });

  const protocols = ['http://', 'https://', 'mailto:', 'tel:', 'sms:'];
  const haveProtocol = protocols.find(protocol => newLink.includes(protocol))
  if (!haveProtocol) {
    newLink = `https://${newLink}`
  }
  if (!validateHttpEmailTel(newLink)) {
    return ''
  }
  return new URL(newLink).href
}

export const removeQueryParamsFromRouter = (router: NextRouter, removeList: Array<string> = []) => {
  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    // Remove all
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: true }
  );
};

export const removeUndefinedValuesFromObject = <T extends NodeJS.Dict<any>>(object: T): T => {
  const cleanedObject = { ...object }
  Object.keys(cleanedObject).forEach(key => cleanedObject[key] === undefined && delete cleanedObject[key])
  return cleanedObject
}

export const getDynamicCampaignVariablesTroughParsedQuery = (query: ParsedUrlQuery) => {
  const dynamicVariables: DynamicVariables = {
    companyname: (query?.companyname as string),
    firstname: (query?.firstname as string),
    lastname: (query?.lastname as string),
    email: (query?.email as string),
    jobtitle: (query?.jobtitle as string),
    domain: (query?.domain as string),
  }
  return removeUndefinedValuesFromObject(dynamicVariables)
}

export const getDynamicCampaignVariables = (text: string) =>
  text?.match(/\{{(.*?)\}}/g) ?? [];

/**
 * Validate if an object has properties
 * @param obj Object to check if has properties or not
 * @returns true if object is empty otherwise false
 */
export const isEmptyObject = (obj: any): boolean =>
  Object.keys(obj)?.length === 0;

export const getLocalStorage = (
  key: string,
  defaultValue: any = false
): any => {
  try {
    if (window && window.localStorage) {
      return window.localStorage.getItem(key);
    }

    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const setLocalStorage = (key: string, value: any) => {
  try {
    if (window && window.localStorage) {
      return window.localStorage.setItem(key, value);
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const copyToClipboard = (
  text: string,
  withSnnipet = false,
  notification: (text: string) => void
) => {
  let finalText = text.replace(/^\s+|\s+$/g, '');
  if (withSnnipet) {
    finalText = text.concat('\n\nVideo Message made by Sendspark');
  }
  clipboard.writeText(finalText);

  notification('Text has been copied!');
};

export const getUrlQuery = (name: string) => {
  const query = window.location.search

  return new URLSearchParams(query).get(name)
}

export const getCookie = (cookieName: string): string | null => {
  const name = cookieName + "="
  let decodedCookie;
  try {
    decodedCookie = decodeURIComponent(document.cookie)
  } catch (e) {
    console.error('Error decoding URI', e)
    return null;
  }
  const cookieArray = decodedCookie.split(";")

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }

  return null
}

export const isDevelopEnvironment = (hostname: string) => {
  return hostname.includes('localhost')
}

export const isCustomDomain = (hostname: string) => {
  const nonCustomDomains = ['sendspark.com', 'stg341.sendspark.com', 'ssoven341-d3vz.com'];
  return !nonCustomDomains.includes(hostname)
}

export const isRootDomain = (hostname: string) => {
  return hostname.split('.').length === 2
}

export const getRootDomain = (hostname: string) => {
  const parts = hostname.split('.')
  if (parts.length === 2) {
    return hostname
  }
  return `https://${parts.slice(-2).join('.')}`
}

export const getDomainName = (hostName: string) => {
  if (!hostName.includes('.')) {
    return hostName
  }

  const parts = hostName.split('.')
  return parts.reverse()[1]
}

export const base64UrlEncode = (data: any) => {
  const base64 = Buffer.from(data).toString('base64')
  return encodeURIComponent(base64)
}

export const base64UrlDecode = (data: any) => {
  const decodedURIComponent = decodeURIComponent(data)
  return Buffer.from(decodedURIComponent, 'base64').toString().replace(/ /g, '+');
}

export const isVideoCreator = (creatorId: string, getLocalStorageV2?: ({ key, crypto }: {
  key: string;
  crypto?: boolean | undefined;
}) => any) => {
  const sessionUserId = getCookie(COOKIE_KEYS.USER_ID)
  const localStorageUserSessionId = getLocalStorageV2?.({ key: LOCAL_STORAGE_KEYS.USER_SESSION_ID, crypto: true })

  if (sessionUserId) {
    return sessionUserId === creatorId
  }

  return localStorageUserSessionId === creatorId
}

export const getHtmlBackground = (campaign: Campaign) => {
  if (campaign?.styles?.backgroundColor) {
    return campaign.styles.backgroundColor
  }

  if (campaign?.style?.background) {
    return campaign.style.background;
  }

  if (campaign?.theme?.name === THEMES?.SENDSPARK) {
    return '#FFFAF4';
  }

  return '#E5E5E5'; // Default value
}

export const applyOpacityToColor = (color: string, opacity: number | string) => {
  // Check if the color is a valid hexadecimal color
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)) {
    return color;
  }

  // Remove the hash from the color
  let hex = color.slice(1);

  // If the color is in short format (#RGB), convert it to long format (RRGGBB)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Convert the color to RGB
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getJwtToken = (cookies: Record<string, string>) => {
  const wosToken = cookies?.[COOKIE_KEYS.WORKOS_SESSION]
  const sendsparkToken = cookies?.[COOKIE_KEYS.SENDSPARK_JWT]

  return wosToken || sendsparkToken
}